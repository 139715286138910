import { Observable, of } from "rxjs";
import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'complete-activity-signature',
    templateUrl: './complete-activity-signature.component.html',
    styleUrls: ['./complete-activity-signature.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivitySignatureComponent implements OnInit
{
    @ViewChild('sigPad') sigPad;

    private sigPadElement = null;

    private context = null;
    private isDrawing = false;
    private img = null;
    
	@Output() uploadSignature: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit() {
        this.sigPadElement = this.sigPad.nativeElement;
        this.context = this.sigPadElement.getContext('2d');
        this.context.strokeStyle = '#3742fa';
		
		/**if (this.data.imageUrl) {
			let image = new Image();
			image.onload = () => {
				this.clear();
				this.context.drawImage(image, 0, 0);
			}
			image.src = this.data.imageUrl;
		}*/
    }
    
    saveChanges(): Observable<any> {
        return of({});
    }
   
    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e) {
    	this.isDrawing = false;
		if (this.sigPadElement != null) {
			this.img = this.sigPadElement.toDataURL("image/jpg");
			this.uploadSignature.emit(this.img.split(',')[1]);
		}
    }

    onMouseDown(e) {
        this.isDrawing = true;
        const coords = this.relativeCoords(e);
        this.context.moveTo(coords.x, coords.y);
    }

    onMouseMove(e) {
        if (this.isDrawing) {
            const coords = this.relativeCoords(e);
            this.context.lineTo(coords.x, coords.y);
            this.context.stroke();
        }
    }

	private relativeCoords(event) {
		const bounds = event.target.getBoundingClientRect();
		const x = event.clientX - bounds.left;
		const y = event.clientY - bounds.top;
		return { x: x, y: y };
	}

	clear() {
		this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
		this.context.beginPath();
	}
}